import React, { useCallback, useEffect, useMemo, useState } from "react";

import formatVenueAddress from "../../../../utils/formatVenueAddress";
import { Hero } from "../../../ui/PackageHero";
import Slide from "../../../ui/Slide";
import * as StyledCrossSellVenue from "./styled";

const CrossSellVenue = ({
  date,
  endpoint,
  isVisible,
  numPeople,
  onResolve,
  venue
}) => {
  const [state, setState] = useState({ isAvailable: false });

  const address = useMemo(() => {
    const { buildingName, city, street, postCode } = venue;

    const addressLines = formatVenueAddress({ buildingName, city, street, postCode });

    return addressLines.join(", ");
  }, [venue]);

  const getAvailability = useCallback(async () => {
    try {
      const response = await fetch(
        `${endpoint}/v1/venue/${venue.id}/timeslots?bookingType=${venue.bookingType}&date=${date}&numPeople=${numPeople}`,
        { headers: { "Content-Type": "application/json" } }
      );

      onResolve(venue.id)

      if (!response.ok || response.status !== 200) {
        throw new Error();
      }
  
      const data = await response.json();

      if (typeof data.timeslots !== "undefined" && setState) {
        setState((previousState) => ({ ...previousState, isAvailable: true, timeslots: data.timeslots }));
      } else {
        throw new Error();
      }
    } catch (_error) {
      if (setState) {
        setState((previousState) => ({ ...previousState, isAvailable: false, timeslots: [] }));
      }
    }
  }, [date, endpoint, numPeople, onResolve, venue.id]);

  const distance = useMemo(() => {
    return Math.round(venue.distance * 10) / 10
  }, [venue.distance]);

  const handleClick = useCallback(
    () => window.open(`${venue.websiteUrl}/?booking_type=${venue.bookingType}&booking_type_category=${venue.bookingTypeCategory}&date=${date}&num_people=${numPeople}`, "_blank"),
    [date, numPeople, venue.bookingType, venue.bookingTypeCategory]
  );

  useEffect(() => {
    if (isVisible) {
      getAvailability();
    } else {
      setState((previousState) => ({ ...previousState, isAvailable: false, timeslots: undefined }));
    }
  }, [isVisible]);

  return (
    <StyledCrossSellVenue.Wrapper isVisible={isVisible}>
      <Hero>
        <Slide>
          <img alt={venue.title} src={venue.venuePickerImageUrl || "https://www.youngs.co.uk/youngs/uploads/sites/2/2021/07/square.jpg"} />
        </Slide>
      </Hero>
      <StyledCrossSellVenue.Content>
        <h6>{venue.title}</h6>
        <p>
          {address}
        </p>
        <p>
          {distance}
          {" "}
          miles away
        </p>
        <span>
          You will be redirected to the
          {" "}
          {venue.title}
          {" "}
          website to complete your booking
        </span>
      </StyledCrossSellVenue.Content>
      <StyledCrossSellVenue.Button
        disabled={!state.isAvailable || typeof state.timeslots === "undefined"}
        onClick={handleClick}
        type="button"
      >
        {!state.isAvailable && typeof state.timeslots === "undefined" && "Checking..."}
        {!state.isAvailable && typeof state.timeslots !== "undefined" && !state.timeslots.length && "No Availability"}
        {state.isAvailable && "Select"}
      </StyledCrossSellVenue.Button>
    </StyledCrossSellVenue.Wrapper>
  )
};

export default CrossSellVenue;
